import styled from "@emotion/styled";
import {Typography} from "@mui/material";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: large;
  margin-left: 14%;
  margin-right: 14%;
  margin-top: 2%;
  @media (max-width: 1400px) {
    margin-left: 11%;
    margin-right: 11%;
  }
  @media (max-width: 768px) {
    font-size: medium;
    margin-left: 7%;
    margin-right: 7%;
  }
  @media (max-width: 600px) {
    margin-left: 3%;
    margin-right: 3%;
    font-size: small;
  }
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const Space = styled.div`
  margin-left: 2vw;
`

export const Link = styled.a`
  color: #00bfff;
  font-weight: bold;
  text-decoration: none;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const ContentBlock = styled.div`
  margin-bottom: 1%;
  margin-top: 1%;
`

export const Page = styled.div`
  background-color: #ffffff;
`

export const Header = styled(Typography)`
  text-decoration: ${props => props.underline ? "underline": "none"};
@media (max-width: 600px) {
  font-size: x-large;
  font-weight: bold;
}
`