import {ContentBlock, Center, Content, Link, Row, Header} from "../Styles";

export const settings = ['About', 'Projects', 'Contact'] //list of menu items

export const AboutContent = () => {
    return (
        <>
            <Content>
                <ContentBlock>
                    <p>
                        I'm Adam, a software developer with over a years experience, primarily with React and Python living
                        in Manchester. I love everything to do with coding, be it learning a new language or
                        framework,
                        figuring out complex problems or designing things like databases!
                    </p>
                </ContentBlock>
                <ContentBlock>
                    <p>
                        Mainly my main languages currently are Javascript and Python, but I am both open and interested
                        into learning new languages and in trying new things whenever I can. Unfortunately there is only
                        24 hours in a day!
                    </p>
                </ContentBlock>
                <ContentBlock>
                    <p>
                        My education for programming mainly comes from a master coding bootcamp I did
                        with <Link
                        href="https://wearecodenation.com/"
                        target="_blank"
                        rel="noreferrer"
                    >CodeNation</Link> which was a 3 month full time course focused on full stack web development.
                        However
                        I am quite open to adapting and learning anything.
                    </p>
                </ContentBlock>
                <ContentBlock>
                    <p>
                        My current job is for a small company
                        called <Link
                        href="https://www.pixelmilldigital.com/"
                        target="_blank"
                        rel="noreferrer"
                    >PixelMill</Link>, which focuses on Virtual Reality projects. Whilst here I have wrote a fullstack
                        application for a web portal (more details on Projects page). In this role I have both worked
                        within a small team and later taken full ownership of this project, so I am confident working
                        alone and taking initiative.
                    </p>
                </ContentBlock>
            </Content>
        </>
    )
}

export const ProjectContent = () => {
    return (
        <>
            <Center>
                <p>
                    Below are some examples of things I have worked on or made:
                </p>
            </Center>

            <Content>
                <ContentBlock>
                    <Header underline={"true"} variant="h4" component="p">
                        ViPER Portal
                    </Header>
                    {/*<img src={viper} height="300" alt="ViPER Portal"/>*/}
                    <p>
                        ViPER Portal is a web portal for users of ViPER products to create an account, buy subscriptions
                        for these products and access data.
                    </p>
                    <p>
                        ViPER consists of 2 main lines of products, player and team
                        allows users to record, view and compare data on exercises performed with a VR app and Coach
                        is an interactive board for displaying players in formations and recording sequences that
                        can be played back to use for coaching football teams.
                    </p>
                    <Row>
                        <Link href="https://portal.futureperformance.tech/login" target="_blank"
                              rel="noreferrer">[Website]</Link>
                    </Row>
                </ContentBlock>
                <ContentBlock>
                    <Header underline={"true"} variant="h4" component="p">
                        URL Server
                    </Header>
                    <p>
                        This is an Opine server (a port of Express js) using Deno, which is a more modern Javascript
                        runtime similar to Node. It uses a database to serve requests regarding URLs with management of
                        what URLS can be served managed with a frontend protected by authentication using JWTs.
                    </p>
                    <p>
                        Usecase is for an application to request a URL for the API it should be calling for its own
                        requests, giving a controllable single point of access.
                    </p>
                    <Row>
                        <Link href="https://github.com/adm-j/deno_url_server" target="_blank" rel="noreferrer">
                            [Repository]
                        </Link>
                    </Row>
                </ContentBlock>
                <ContentBlock>
                    <Header underline={"true"} variant="h4" component="p">
                        RK9 Data Scrapper
                    </Header>
                    <p>
                        This data scrapper was created for my personal need to get a lot of data - playing in a online
                        trading card game tournament, my friends were recording every deck every player used in order to
                        know what their opponents played in the previous round. The problem was especially at the
                        beginning there were over 500 teams with 5 players each.
                    </p>
                    <p>
                        This Node script uses Cheerio to get the relevant information from the RK9 website to loop
                        through using Puppeteer and take screenshots of each players deck and organise those images
                        into folders for each team.
                    </p>
                    <Row>
                        <Link href="https://github.com/adm-j/team_challenge_scraper" target="_blank" rel="noreferrer">
                            [Repository]
                        </Link>
                    </Row>
                </ContentBlock>
            </Content>

        </>
    )
}

export const ContactContent = () => {
    return (
        <Center>
            <p>
                Contact me about work opportunities
                by <Link href="mailto:enquiries@adam-jones.me?subject=Work%20Opportunity">emailing me</Link> or contacting me
                on <Link href="https://www.linkedin.com/in/admmj/" target="_blank" rel="noreferrer">Linkedin</Link>!
            </p>
        </Center>
    )
}