import './App.css';
import {NavMenu} from "./Layout/NavBar";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import {About} from "./Components/About";
import {Content} from "./Styles";
import {Contact} from "./Components/Contact";
import {Projects} from "./Components/Projects";

function App() {

    return (
            <Router>
                <NavMenu>
                    <Content>
                        <Routes>
                            <Route
                                path="/About"
                                element={<About/>}
                            />
                            <Route
                                path="/Projects"
                                element={<Projects/>}
                            />
                            <Route
                                path="/Contact"
                                element={<Contact/>}
                            />
                        </Routes>
                    </Content>
                </NavMenu>
            </Router>
    );
}

export default App;
