import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import github from '../../img/github.svg'
import linkedin from '../../img/linkedin.svg'
import {Center, Space} from "../../Styles";
import {useMediaQuery} from "@mui/material";
import {settings} from "../../Content";

export const NavMenu = ({children}) => {
    const [page, setPage] = useState(window.location.pathname.slice(1))
    const [anchorEl, setAnchorEl] = useState(null);

    const useBurgerMenu = useMediaQuery('(min-width: 600px)')
    let navigate = useNavigate();

    useEffect(() => {
        switch (window.location.pathname) {
            case "/About":
                navigate("/About")
                setPage("About")
                break;
            case "/Projects":
                navigate("/Projects")
                setPage("Projects")
                break;
            case "/Contact":
                navigate("/Contact")
                setPage("Contact")
                break;
            default:
                navigate("/About")
                setPage("About")
        }
    }, [navigate])

    const handleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuSelection = (option) => {
        setPage(option)
        if (option === "About") {
            navigate("/About")
        }
        if (option === "Projects") {
            navigate("/Projects")
        }
        if (option === "Contact") {
            navigate("/Contact")
        }
    }

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="false" sx={{backgroundColor: "#18282b"}}>
                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 1, display: "flex", flexDirection: "row"}}>
                            <Space>
                                <a href="https://github.com/adm-j" target="_blank" rel="noreferrer">
                                    <img src={github} alt="GitHub"/>
                                </a>
                            </Space>
                            <Space>
                                <a href="https://www.linkedin.com/in/admmj/" target="_blank" rel="noreferrer">
                                    <img src={linkedin} alt="linkedin"/>
                                </a>
                            </Space>
                        </Box>

                        {/*<Box sx={{flexGrow: 1}}>*/}
                        {/*    <Typography*/}
                        {/*        variant="h5"*/}
                        {/*        fontWeight="bold"*/}
                        {/*        noWrap*/}
                        {/*        component="div"*/}
                        {/*        sx={{mr: 2, display: {xs: "flex", md: 'flex'}}}*/}
                        {/*    >*/}
                        {/*        {page}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}

                        {useBurgerMenu ?
                            <Box sx={{display: "flex", flexDirection: "row"}}>
                                {
                                    settings.map((setting, index) =>
                                        <p
                                            style={{cursor: "pointer"}}
                                            key={index}
                                            onClick={() => handleMenuSelection(setting)}
                                        >
                                            {(index === 0 ? "" : "/") + setting}
                                        </p>
                                    )
                                }
                            </Box>
                            :
                            <Box>
                                <Tooltip title="Open menu">
                                    <IconButton onClick={handleOpenUserMenu} color="inherit" sx={{p: 1}}>
                                        <MenuIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => {
                                            handleMenuSelection(setting)
                                            handleCloseUserMenu()
                                        }
                                        }>
                                            <Typography textAlign="center">
                                                {setting}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        }

                    </Toolbar>
                </Container>
            </AppBar>
            <div>
                <Center>
                    <Typography variant="h2" component="p">{page}</Typography>
                </Center>
                {children}
            </div>
        </>
    );
};
